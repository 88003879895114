<template>
  <div class="box box-menu is-paddingless">
    <aside
      v-if="!showMobileMenu"
      class="menu-page menu"
    >
      <ul class="menu-list">
        <li
          v-for="(item, index) in menuItems"
          :key="'m_' + index"
        >
          <a
            @click="updateSelectedMenuIndex(index)"
            :class="{ 'is-active' : index === selectedMenuItems[menuGroupIndex] }"
          >
            <span
              v-bind="addBadge(item)"
              :class="addBadgeClass(item)"
            >{{ item.Name }}</span>
          </a>
        </li>
      </ul>
    </aside>
    <div
      v-else
      class="wrapper-select has-padding"
    >
      <div class="select">
        <select>
          <option
            v-for="(item, index) in menuItems"
            :key="'dm_' + index"
            :value="index"
            :selected="index === selectedMenuItems[menuGroupIndex]"
          >{{item.Name}}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import EventBus from '../../eventbus/event-bus'
export default {
  name: 'page-menu',

  props: {
    clickActionFunc: {
      type: Function,
      default: function() { return false }
    },
    menuItems: {
      type: Array,
      default: function() { return [] }
    }
  },

  data() {
    return {
      menuGroupIndex: 2,
      selectedMenuItemIndex: 0,
      showMobileMenu: window.innerWidth < 1024
    }
  },

  computed: {
    ...mapState('menuStore', ['selectedMenuItems'])
  },

  created() {
    let self = this
    EventBus.$on('windowResizeListener', val => {
      self.showMobileMenu = val.width < 1024
    })
  },

  beforeDestroy() {
    EventBus.$off('windowResizeListener')
  },

  methods: {
    ...mapMutations('menuStore', ['setSelectedMenuItemIndex', 'setVisiblePageComponent']),

    updateSelectedMenuIndex(index) {
      // Update selected page menu item
      this.setSelectedMenuItemIndex({
        menuGroupIndex: this.menuGroupIndex,
        selectedMenuItemIndex: Number(index)
      })
      this.setVisiblePageComponent(this.menuItems[index].Link)
      // this.menuItems[index].Action(this.menuItems[index], index)
    },

    addBadge() { },
    addBadgeClass() { },
    hasCustomClickFunc() { }
  }

}
</script>

<style lang="scss" scoped>
// .menu-page {
//   transition: all 0.5s ease-in-out;
//   animation-duration: 0.5s;
//   max-height: 500px;
//   @media screen and (max-width: 1023px) {
//     max-height: 0px;
//     overflow: hidden;
//     opacity: 0;
//   }
// }
</style>